import Vue from 'vue'
import Vuex from 'vuex'
import auth from './module/auth'
import user from './module/user'
import role from './module/role'
import opd from './module/opd'
import docs from './module/docs'
import gallery from './module/gallery'
import report from './module/report'
import message from './module/message'
import room from './module/room'
import report_citizen from './module/report-citizen'
import history_chat from './module/history-chat'
import schedule from './module/schedule'
import paket_pekerjaan from './module/paket-pekerjaan'
import paket_pekerjaan_public from './module/paket-pekerjaan-public'
import potensi_pasar from './module/potensi-pasar'
import quarry from './module/quarry'
import batas_wilayah from './module/batas-wilayah'
import lab from './module/lab'
import alat_kontruksi from './module/alat-kontruksi'
import sbu from './module/sbu'
import ska from './module/ska'
import skt from './module/skt'
import category from './module/category'
import category_shp from './module/category_shp'
import shp from './module/shp'
import tender_selesai from './module/tender-selesai'
import master_rup from './module/master-rup'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  modules: {
    auth,
    user,
    role,
    opd,
    docs,
    gallery,
    report,
    message,
    room,
    report_citizen,
    history_chat,
    schedule,
    paket_pekerjaan,
    paket_pekerjaan_public,
    potensi_pasar,
    quarry,
    batas_wilayah,
    lab,
    alat_kontruksi,
    sbu,
    ska,
    skt,
    category,
    tender_selesai,
    master_rup,
    shp,
    category_shp
  },
  mutations
})
