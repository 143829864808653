import "core-js/stable"
import Vue from "vue"
import App from "./App"
import router from "./router"
import CoreuiVue from "@coreui/vue"
import { iconsSet as icons } from "./assets/icons/icons.js"
import store from "./store/index.js"
import axios from "axios"
//  vue loading
import VueLoading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
// pagination
import Pagination from "vue-pagination-2"
// vue toast
import VueToast from "vue-toast-notification"
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css"
//  select multiple
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
// select
Vue.component("v-select", vSelect)

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

// setup base url
Vue.prototype.$http = axios
// axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://10.90.174.125/rest/api/';
axios.defaults.baseURL = 'https://sijaga.kabbdg.com/rest/api/';
// axios.defaults.baseURL = "http://localhost:8000/api/"

// add token to localstorage
if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] =
    "bearer " + localStorage.getItem("token")
}
// check valid token
axios.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      console.log("You are not authorized")
    }
    return response
  },
  (error) => {
    if (error.response && error.response.data) {
      if (
        error.response.data.status == 401 ||
        error.response.data.status == 403
      ) {
        store.dispatch("auth/logout")
        router.replace("/pages/login")
      }
      return Promise.reject(error.response.data.message)
    }
    return Promise.reject(error.message)
  }
)

// guard for auth
router.beforeEach((to, from, next) => {
  if (to.path === "/") {
    next("/pengaduan/home")
    return
  }

  if (to.path === "/pengaduan/home" || to.path === "/pengaduan/list" || to.path === "/pengaduan/create") {
    next() 
    return
  }

  var hasToken = localStorage.getItem("token")
  if (to.path != "/pages/login") {
    if (hasToken) {
      next()
      return
    }
    next("/pages/login")
    return
  } else {
    if (hasToken) {
      next({ path: "/dashboard" })
      return
    }
    // next();

    // return
  }

  next()
})

// setup overlay loading
Vue.use(
  VueLoading,
  {
    // props
    color: "blue",
    loader: "dots",
    height: 64,
    width: 64,
  },
  {
    // slots
  }
)

//  setup toast
Vue.use(VueToast, {
  // One of the options
  position: "top-right",
  duration: 2000,
})

// pagination
Vue.component("pagination", Pagination)

// Firebase
import firebase from "firebase/compat/app"
import "firebase/compat/storage"
import "firebase/compat/analytics"
import "firebase/compat/database"
import "firebase/compat/auth"
import "firebase/compat/firestore"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsUmHxqP_vQKDTXlzrJdAYu2QFgjMXgrM",
  authDomain: "gps-tracker-3c2a1.firebaseapp.com",
  databaseURL: "https://gps-tracker-3c2a1.firebaseio.com",
  projectId: "gps-tracker-3c2a1",
  storageBucket: "gps-tracker-3c2a1.appspot.com",
  messagingSenderId: "893074004983",
  appId: "1:893074004983:web:f27abdb4faaa62d395acf2",
}

firebase.initializeApp(firebaseConfig)

// Select-2
import Select2 from "v-select2-component"

Vue.component("Select2", Select2)

// moment
const moment = require("moment")
require("moment/locale/id")

Vue.use(require("vue-moment"), {
  moment,
})

//  scroll chat
import VueChatScroll from "vue-chat-scroll"
Vue.use(VueChatScroll)

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
})
